import { useColorMode } from '@chakra-ui/react';
import React from 'react';

export const Brand = ({ transparent, stuck, height = '50%' }) => {
  const { colorMode } = useColorMode();

  let fill = ['#619ee8', '#4773a8', '#e4f0fc']; // Base: light

  if (colorMode === 'light') {
    if (transparent && !stuck) {
      fill[2] = '#ffffff';
    }
  } else {
    fill = ['#6baeff', '#4773a8', '#475778']; // Base: dark
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1802.05 227.42"
      style={{ height }}
    >
      <title>Binum Development</title>
      <g>
        <path
          fill={fill[0]}
          d="M201.14,209.85V5.2h70.14q34.72,0,52.85,13.92t18.13,41.46a44.19,44.19,0,0,1-7.59,25.3q-7.59,11.24-22.21,17.43,16.59,4.5,25.65,16.94t9.07,29.87q0,28.81-18.48,44.28t-52.92,15.46ZM236.7,91.36h34.86q16.59,0,25.93-7.52t9.35-21.29q0-15.18-8.64-21.93t-26.92-6.75H236.7Zm0,26.14v64h39.5q16.73,0,26.14-8.29t9.42-23.05q0-31.91-32.61-32.61Z"
        />
        <path
          fill={fill[0]}
          d="M379.51,18.27a18.13,18.13,0,0,1,5-13.07q5-5.2,14.27-5.2T413.1,5.2a18,18,0,0,1,5.06,13.07,17.57,17.57,0,0,1-5.06,12.86q-5.06,5.13-14.34,5.13T384.5,31.13A17.71,17.71,0,0,1,379.51,18.27Zm36.26,191.58H381.61V57.77h34.16Z"
        />
        <path
          fill={fill[0]}
          d="M484.64,57.77l1,17.57Q502.49,55,529.9,55q47.51,0,48.35,54.4v100.5H544.1V111.32q0-14.48-6.25-21.44t-20.45-7q-20.66,0-30.78,18.69V209.85H452.46V57.77Z"
        />
        <path
          fill={fill[0]}
          d="M705.18,195q-15,17.71-42.73,17.71-24.74,0-37.46-14.48T612.27,156.3V57.77h34.16v98.11q0,29,24,29,24.88,0,33.59-17.85V57.77h34.16V209.85H706Z"
        />
        <path
          fill={fill[0]}
          d="M805.11,57.77l1,15.88Q822.12,55,850,55q30.5,0,41.75,23.33Q908.28,55,938.36,55q25.16,0,37.45,13.92t12.58,41v99.94H954.25v-99q0-14.48-6.33-21.22T927,82.93q-11.67,0-19,6.25a32.93,32.93,0,0,0-10.34,16.37l.14,104.29H863.59V109.78q-.7-26.85-27.41-26.85-20.52,0-29.1,16.73v110.2H772.93V57.77Z"
        />
        <path
          fill={fill[0]}
          d="M1105.49,209.85V5.2h57.77Q1190,5.2,1210.48,17a79.78,79.78,0,0,1,31.69,33.59q11.19,21.79,11.32,50v13.07q0,29-11.17,50.74a78.68,78.68,0,0,1-31.92,33.45q-20.73,11.67-48.28,11.95Zm27-182.58V187.79h28.39q31.2,0,48.57-19.41t17.35-55.29v-12q0-34.89-16.38-54.23T1164,27.27Z"
        />
        <path
          fill={fill[0]}
          d="M1409.93,115.26h-88.69v72.53h103v22.07h-130V5.2h128.61V27.27H1321.24V93.19h88.69Z"
        />
        <path fill={fill[0]} d="M1467.42,5.2H1438l60.43,163.23,13.29-34.57Z" />
        <path
          fill={fill[1]}
          d="M1026.21,196.22a16.24,16.24,0,0,1,4-11.24q4-4.5,12-4.5t12.16,4.5a16,16,0,0,1,4.14,11.24A15.13,15.13,0,0,1,1054.4,207q-4.16,4.36-12.16,4.36t-12-4.36A15.35,15.35,0,0,1,1026.21,196.22Z"
        />
        <path fill={fill[1]} d="M1528.48,227.42h-29.7L1584.25,5.2h29.56Z" />
        <path
          fill={fill[2]}
          d="M1765.88,117.24,1642,68.7V33.1l160,70.84V131.3l-160,71v-36Z"
        />
        <path
          fill={fill[2]}
          d="M160,166.34v36L0,131.3V103.94L160,33.1V68.7L36.17,117.24Z"
        />
      </g>
    </svg>
  );
};

export default Brand;
