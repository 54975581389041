import React from 'react';
import Header from '../components/sections/Header';
import Hero from '../components/sections/Hero';
import siteNav from '../configs/site-nav.json';

export const App = () => (
  <>
    <Header navRoutes={siteNav.routes} transparent />
    <Hero />
  </>
);

export default App;
