import React from 'react';
import {
  Button,
  chakra,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { MdMail } from 'react-icons/md';
import CloudsSvg from './Clouds.svg';

const CloudsSvgResponsive = chakra(CloudsSvg);

export const Hero = () => {
  const c = {
    base: useColorModeValue('brand.600', 'brand.500'),
    accent: useColorModeValue('brand.300', 'brand.200'),
  }; // Text colors

  return (
    <chakra.section
      w="100%"
      bg={useColorModeValue('brand.50', 'brand.900')}
      overflow="hidden"
      px="4"
      boxSizing="border-box"
    >
      <Stack
        maxW="1200px"
        h={{ base: '100vh', md: '80vh' }}
        minH="600px"
        mx="auto"
        pt="4rem"
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        justifyContent={{ base: 'space-evenly', md: 'space-between' }}
        position="relative"
        spacing="0"
      >
        <VStack
          alignItems={{ base: 'center', md: 'flex-start' }}
          spacing="10"
          w="100%"
        >
          <Text
            textAlign={{ base: 'center', md: 'left' }}
            color={c.base}
            casing="uppercase"
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl', lg: '5xl' }}
            fontWeight="medium"
            lineHeight="1.2"
          >
            Custom{' '}
            <chakra.span color={c.accent} fontWeight="bold">
              Web
            </chakra.span>{' '}
            and{' '}
            <chakra.span color={c.accent} fontWeight="bold">
              Mobile
            </chakra.span>
            <br />
            Application development
          </Text>

          <Text color={c.base} fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}>
            Powered by{' '}
            <chakra.span color={c.accent} fontWeight="medium">
              Cloud technologies
            </chakra.span>
          </Text>

          <Button
            as="a"
            href="mailto:binum.development@outlook.com"
            leftIcon={<MdMail />}
            size={useBreakpointValue({ base: 'md', md: 'lg' })}
            colorScheme="brand"
            color={useColorModeValue('white', 'whiteAlpha.900')}
            border="2px"
            borderColor={useColorModeValue('white', 'gray.900')}
            bg={useColorModeValue('brand.300', 'brand.500')}
          >
            Let's talk about your project
          </Button>
        </VStack>
        <chakra.div minHeight={{ base: '25vh' }}>
          <chakra.div
            position={{ base: 'relative', md: 'absolute' }}
            top="auto"
            bottom="0"
            left={{ base: 'auto', md: 'auto' }}
            right={{ base: 'auto', md: '-3rem' }}
            ml={{ base: 'auto', md: 0 }}
            w={{ base: '80vw', md: '50%' }}
            h={{ base: '100%', md: 'auto' }}
            verticalAlign="middle"
          >
            <CloudsSvgResponsive />
          </chakra.div>
        </chakra.div>
      </Stack>
    </chakra.section>
  );
};

export default Hero;
