const colors = {
  brand: {
    50: '#e3f2ff',
    100: '#b9d6f9',
    200: '#8fbbf0',
    300: '#639fe8', // base #619EE8
    400: '#3a84e1',
    500: '#216bc7',
    600: '#17539c',
    700: '#0d3b70',
    800: '#032446',
    900: '#000d1c',
  },
};

export default colors;
